import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';


@Injectable({
  providedIn: 'root'
})
export class NotificacaoService {

  constructor(private localNotifications: LocalNotifications, public plt: Platform) { }

  notificar(mensasgem: string) {
    // Schedule a single notification
    // console.log("this.plt.is('cordova')", this.plt.is('cordova'))
    // if (this.plt.is('cordova')) {

      this.localNotifications.schedule({
        id: 1,
        text: mensasgem,
        sound: this.plt.is('ios') ? 'file://beep.caf' : 'file://sound.mp3',
        data: { secret: "key" }
      });
    // }
  }
}
