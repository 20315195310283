import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'integer'
})
export class IntegerPipe implements PipeTransform {

  transform(value: number, ...args: any[]): any {
    if (value) {
      return Math.round(value).toLocaleString('de-DE');
    } else {
      return "0";
    }
  }
}
