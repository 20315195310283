import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-confirmar-cancelar-modal',
    templateUrl: './confirmar-cancelar-modal.component.html',
    styleUrls: ['./confirmar-cancelar-modal.component.scss'],
})
export class ConfirmarCancelarModalComponent implements OnInit {
    constructor(
        private dialogRef: MatDialogRef<ConfirmarCancelarModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
        //data.textoLegenda
        //data.textoSalvar
        //data.textoCancelar
    }

    cancelar() {
        this.dialogRef.close(false);
    }

    async salvar() {
        this.dialogRef.close(true);
    }
}
