import { DoublePipe } from './../double/double.pipe';
import { GramasPipe } from './../gramas/gramas.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReservatorioLitroKiloPipe } from '../reservatorioLitroKilo/reservatorio-litro-kilo.pipe';
import { DatasPipe } from '../datas/datas.pipe';
import { IntegerPipe } from '../integer/integer.pipe';
import { QuilogramasPipe } from '../quilogramas/quilogramas.pipe';
import { TipoPecaPipe } from '../tipo-peca.pipe';
import { InstrucaoManutencaoPipe } from '../instrucao-manutencao/instrucao-manutencao.pipe';
import { TipoPecaParaTipoManutencaoPipe } from '../tipo-peca-para-tipo-manutencao/tipo-peca-para-tipo-manutencao.pipe';
import { DataNotificacao } from '../datas/datas-notificacao.pipe';
import { CodigoPecaZeroSemPecaPipe } from '../codigo-peca-zero-sem-peca.pipe';

@NgModule({
    declarations: [
        GramasPipe,
        DoublePipe,
        ReservatorioLitroKiloPipe,
        DatasPipe,
        IntegerPipe,
        QuilogramasPipe,
        TipoPecaPipe,
        InstrucaoManutencaoPipe,
        TipoPecaParaTipoManutencaoPipe,
        DataNotificacao,
        CodigoPecaZeroSemPecaPipe,
    ],
    imports: [CommonModule],
    exports: [
        GramasPipe,
        DoublePipe,
        ReservatorioLitroKiloPipe,
        DatasPipe,
        IntegerPipe,
        QuilogramasPipe,
        TipoPecaPipe,
        InstrucaoManutencaoPipe,
        TipoPecaParaTipoManutencaoPipe,
        CodigoPecaZeroSemPecaPipe,
    ],
})
export class PipeModule {}
