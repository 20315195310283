import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'notificacao-view',
        pathMatch: 'full',
    },
    {
        path: 'usuario-view',
        loadChildren: () =>
            import('./page/usuario/usuario-view/usuario-view.module').then(
                (m) => m.UsuarioViewPageModule
            ),
    },
    {
        path: 'usuario-form',
        loadChildren: () =>
            import(
                './page/usuario/usuario-form/usuario-form/usuario-form.module'
            ).then((m) => m.UsuarioFormPageModule),
    },
    {
        path: 'login',
        loadChildren: () =>
            import('./page/login/login/login.module').then(
                (m) => m.LoginPageModule
            ),
    },
    {
        path: 'alimentador-view',
        loadChildren: () =>
            import(
                './page/alimentador/alimentador-view/alimentador-view.module'
            ).then((m) => m.AlimentadorViewPageModule),
    },
    {
        path: 'iniciar-ciclo',
        loadChildren: () =>
            import(
                './page/alimentador/iniciar-ciclo/iniciar-ciclo/iniciar-ciclo.module'
            ).then((m) => m.IniciarCicloPageModule),
    },
    {
        path: 'alimentador-info',
        loadChildren: () =>
            import(
                './page/alimentador/alimentador-info/alimentador-info/alimentador-info.module'
            ).then((m) => m.AlimentadorInfoPageModule),
    },
    {
        path: 'alimentador-historico',
        loadChildren: () =>
            import(
                './page/alimentador/alimentador-historico/alimentador-historico/alimentador-historico.module'
            ).then((m) => m.AlimentadorHistoricoPageModule),
    },
    {
        path: 'alimentador-historico-dia',
        loadChildren: () =>
            import(
                './page/alimentador/alimentador-historico-dia/alimentador-historico-dia/alimentador-historico-dia.module'
            ).then((m) => m.AlimentadorHistoricoDiaPageModule),
    },
    {
        path: 'alimentador-biometria',
        loadChildren: () =>
            import(
                './page/alimentador/alimentador-biometria/alimentador-biometria.module'
            ).then((m) => m.AlimentadorBiometriaPageModule),
    },
    {
        path: 'alimentador-mortalidade',
        loadChildren: () =>
            import(
                './page/alimentador/alimentador-mortalidade/alimentador-mortalidade.module'
            ).then((m) => m.AlimentadorMortalidadePageModule),
    },
    {
        path: 'racao-view',
        loadChildren: () =>
            import('./page/racao/racao-view/racao-view.module').then(
                (m) => m.RacaoViewPageModule
            ),
    },
    {
        path: 'racao-form',
        loadChildren: () =>
            import('./page/racao/racao-form/racao-form.module').then(
                (m) => m.RacaoFormPageModule
            ),
    },
    {
        path: 'tabela-temperatura-view',
        loadChildren: () =>
            import(
                './page/tabela-temperatura/tabela-temperatura-view/tabela-temperatura-view.module'
            ).then((m) => m.TabelaTemperaturaViewPageModule),
    },
    {
        path: 'tabela-temperatura-form',
        loadChildren: () =>
            import(
                './page/tabela-temperatura/tabela-temperatura-form/tabela-temperatura-form.module'
            ).then((m) => m.TabelaTemperaturaFormPageModule),
    },
    {
        path: 'tabela-biomassa-view',
        loadChildren: () =>
            import(
                './page/tabela-biomassa/tabela-biomassa-view/tabela-biomassa-view.module'
            ).then((m) => m.TabelaBiomassaViewPageModule),
    },
    {
        path: 'tabela-biomassa-form',
        loadChildren: () =>
            import(
                './page/tabela-biomassa/tabela-biomassa-form/tabela-biomassa-form.module'
            ).then((m) => m.TabelaBiomassaFormPageModule),
    },
    {
        path: 'tabela-cronograma-view',
        loadChildren: () =>
            import(
                './page/tabela-cronograma/tabela-cronograma-view/tabela-cronograma-view.module'
            ).then((m) => m.TabelaCronogramaViewPageModule),
    },
    {
        path: 'tabela-cronograma-form',
        loadChildren: () =>
            import(
                './page/tabela-cronograma/tabela-cronograma-form/tabela-cronograma-form.module'
            ).then((m) => m.TabelaCronogramaFormPageModule),
    },
    {
        path: 'alimentador-calibrar',
        loadChildren: () =>
            import(
                './page/alimentador/alimentador-calibrar/alimentador-calibrar.module'
            ).then((m) => m.AlimentadorCalibrarPageModule),
    },
    {
        path: 'tanque-view',
        loadChildren: () =>
            import('./page/tanque/tanque-view/tanque-view.module').then(
                (m) => m.TanqueViewPageModule
            ),
    },
    {
        path: 'tanque-form',
        loadChildren: () =>
            import('./page/tanque/tanque-form/tanque-form.module').then(
                (m) => m.TanqueFormPageModule
            ),
    },
    {
        path: 'alimetador-form',
        loadChildren: () =>
            import(
                './page/alimentador/alimentador-form/alimentador-form.module'
            ).then((m) => m.AlimetadorFormPageModule),
    },
    {
        path: 'ciclo-view',
        loadChildren: () =>
            import('./page/ciclo/ciclo-view/ciclo-view.module').then(
                (m) => m.CicloViewPageModule
            ),
    },
    {
        path: 'recarga-form',
        loadChildren: () =>
            import('./page/recarga/recarga-form/recarga-form.module').then(
                (m) => m.RecargaFormPageModule
            ),
    },
    {
        path: 'historico-view',
        loadChildren: () =>
            import('./page/ciclo/ciclo-view/ciclo-view.module').then(
                (m) => m.CicloViewPageModule
            ),
    },
    {
        path: 'notificacao-view',
        loadChildren: () =>
            import(
                './page/notificacao/notificacao-view/notificacao-view.module'
            ).then((m) => m.NotificacaoViewPageModule),
    },
    {
        path: 'recarga-view',
        loadChildren: () =>
            import('./page/recarga/recarga-view/recarga-view.module').then(
                (m) => m.RecargaViewPageModule
            ),
    },
    {
        path: 'alimentador-recarga-view',
        loadChildren: () =>
            import(
                './page/alimentador/alimentador-recarga-view/alimentador-recarga-view.module'
            ).then((m) => m.AlimentadorRecargaViewPageModule),
    },
    {
        path: 'alimentador-despesca-form',
        loadChildren: () =>
            import(
                './page/alimentador/alimentador-despesca-form/alimentador-despesca-form.module'
            ).then((m) => m.AlimentadorDespescaFormPageModule),
    },
    {
        path: 'recuperar-senha',
        loadChildren: () =>
            import('./page/recuperar-senha/recuperar-senha.module').then(
                (m) => m.RecuperarSenhaPageModule
            ),
    },
    {
        path: 'confirmar-nova-senha',
        loadChildren: () =>
            import(
                './page/confirmar-nova-senha/confirmar-nova-senha.module'
            ).then((m) => m.ConfirmarNovaSenhaPageModule),
    },
    {
        path: 'recarga-modal',
        loadChildren: () =>
            import('./component/recarga-modal/recarga-modal.component').then(
                (m) => m.RecargaModalComponent
            ),
    },
    {
        path: 'fornecedor-alevino',
        loadChildren: () =>
            import(
                './page/fornecedorAlevino/fornecedor-alevino-view/fornecedor-alevino.module'
            ).then((m) => m.FornecedorAlevinoPageModule),
    },
    {
        path: 'fornecedor-alevino-form',
        loadChildren: () =>
            import(
                './page/fornecedorAlevino/fornecedor-alevino-form/fornecedor-alevino-form.module'
            ).then((m) => m.FornecedorAlevinoFormPageModule),
    },
    {
        path: 'arracoamento-manual',
        loadChildren: () =>
            import(
                './page/arracoamentoManual/arracoamento-manual/arracoamento-manual.module'
            ).then((m) => m.ArracoamentoManualPageModule),
    },
    {
        path: 'pagina-mortalidade',
        loadChildren: () =>
            import('./page/pagina-mortalidade/pagina-mortalidade.module').then(
                (m) => m.PaginaMortalidadePageModule
            ),
    },
    {
        path: 'alimentador-historico-vazao',
        loadChildren: () =>
            import(
                './page/alimentador/alimentador-historico-vazao/alimentador-historico-vazao.module'
            ).then((m) => m.AlimentadorHistoricoVazaoPageModule),
    },
    {
        path: 'relatorios-view',
        loadChildren: () =>
            import(
                './page/relatorios/relatorios-view/relatorios-view.module'
            ).then((m) => m.RelatoriosViewPageModule),
    },
    {
        path: 'filtro-padrao-relatorio',
        loadChildren: () =>
            import(
                './page/filtro-padrao-relatorio/filtro-padrao-relatorio.module'
            ).then((m) => m.FiltroPadraoRelatorioPageModule),
    },
    {
        path: 'relatorio-racao',
        loadChildren: () =>
            import(
                './page/relatorios/relatorio-racao/relatorio-racao.module'
            ).then((m) => m.RelatorioRacaoPageModule),
    },
    {
        path: 'relatorio-mortalidade',
        loadChildren: () =>
            import(
                './page/relatorios/relatorio-mortalidade/relatorio-mortalidade.module'
            ).then((m) => m.RelatorioMortalidadePageModule),
    },
    {
        path: 'relatorio-racao',
        loadChildren: () =>
            import(
                './page/relatorios/relatorio-racao/relatorio-racao.module'
            ).then((m) => m.RelatorioRacaoPageModule),
    },
    {
        path: 'relatorio-previsao-despesca',
        loadChildren: () =>
            import(
                './page/relatorios/relatorio-previsao-despesca/relatorio-previsao-despesca.module'
            ).then((m) => m.RelatorioPrevisaoDespescaPageModule),
    },
    {
        path: 'fase-form',
        loadChildren: () =>
            import('./page/fase/fase-form/fase-form.module').then(
                (m) => m.FaseFormPageModule
            ),
    },
    {
        path: 'fase-view',
        loadChildren: () =>
            import('./page/fase/fase-view/fase-view.module').then(
                (m) => m.FaseViewPageModule
            ),
    },
    {
        path: 'lote-view',
        loadChildren: () =>
            import('./page/lote/lote-view/lote-view.module').then(
                (m) => m.LoteViewPageModule
            ),
    },
    {
        path: 'lote-historico-view',
        loadChildren: () =>
            import('./page/lote/lote-view/lote-view.module').then(
                (m) => m.LoteViewPageModule
            ),
    },
    {
        path: 'lote-form',
        loadChildren: () =>
            import('./page/lote/lote-form/lote-form.module').then(
                (m) => m.LoteFormPageModule
            ),
    },
    {
        path: 'cadastros-view',
        loadChildren: () =>
            import('./page/cadastros-view/cadastros-view.module').then(
                (m) => m.CadastrosViewPageModule
            ),
    },
    {
        path: 'relatorio-tanque',
        loadChildren: () =>
            import(
                './page/relatorios/relatorio-tanque/relatorio-tanque.module'
            ).then((m) => m.RelatorioTanquePageModule),
    },
    {
        path: 'lote-info',
        loadChildren: () =>
            import('./page/lote/lote-info/lote-info.module').then(
                (m) => m.LoteInfoPageModule
            ),
    },
    {
        path: 'desligamento',
        loadChildren: () =>
            import('./page/desligamento/desligamento.module').then(
                (m) => m.DesligamentoPageModule
            ),
    },
    {
        path: 'relatorio-ciclo-finalizado',
        loadChildren: () =>
            import(
                './page/relatorios/relatorio-ciclo-finalizado/relatorio-ciclo-finalizado.module'
            ).then((m) => m.RelatorioCicloFinalizadoPageModule),
    },
    {
        path: 'taxa-extra',
        loadChildren: () =>
            import('./page/taxa-extra/taxa-extra.module').then(
                (m) => m.TaxaExtraPageModule
            ),
    },
    {
        path: 'relatorio-evolucao-temperatura',
        loadChildren: () =>
            import(
                './page/relatorios/relatorio-evolucao-temperatura/relatorio-evolucao-temperatura.module'
            ).then((m) => m.RelatorioEvolucaoTemperaturaPageModule),
    },
    {
        path: 'relatorio-racao-agrupado',
        loadChildren: () =>
            import(
                './page/relatorios/relatorio-racao-agrupado/relatorio-racao-agrupado.module'
            ).then((m) => m.RelatorioRacaoAgrupadoPageModule),
    },
    {
        path: 'recarga-rapida',
        loadChildren: () =>
            import('./page/recarga-rapida/recarga-rapida.module').then(
                (m) => m.RecargaRapidaPageModule
            ),
    },
    {
        path: 'biometria',
        loadChildren: () =>
            import('./page/biometria/biometria.module').then(
                (m) => m.BiometriaPageModule
            ),
    },
    {
        path: 'historicos-view',
        loadChildren: () =>
            import('./page/historicos-view/historicos-view.module').then(
                (m) => m.HistoricosViewPageModule
            ),
    },
    {
        path: 'visao-geral-piscicultura',
        loadChildren: () =>
            import(
                './page/visao-geral-piscicultura/visao-geral-piscicultura.module'
            ).then((m) => m.VisaoGeralPisciculturaPageModule),
    },
    {
        path: 'tipo-manutencao-alimentador-form',
        loadChildren: () =>
            import(
                './page/tipo-manutencao-alimentador/tipo-manutencao-alimentador-form/tipo-manutencao-alimentador-form.module'
            ).then((m) => m.TipoManutencaoAlimentadorFormPageModule),
    },
    {
        path: 'tipo-manutencao-alimentador-view',
        loadChildren: () =>
            import(
                './page/tipo-manutencao-alimentador/tipo-manutencao-alimentador-view/tipo-manutencao-alimentador-view.module'
            ).then((m) => m.TipoManutencaoAlimentadorViewPageModule),
    },
    {
        path: 'alimentador-manutencao',
        loadChildren: () =>
            import(
                './page/alimentador/alimentador-manutencao/alimentador-manutencao.module'
            ).then((m) => m.AlimentadorManutencaoPageModule),
    },
    {
        path: 'pagina-recargas',
        loadChildren: () =>
            import(
                './page/relatorios/pagina-recargas/pagina-recargas.module'
            ).then((m) => m.PaginaRecargasPageModule),
    },
    {
        path: 'pecas-view',
        loadChildren: () =>
            import('./page/Pecas/pecas-view/pecas-view.module').then(
                (m) => m.PecasViewPageModule
            ),
    },
    {
        path: 'pecas-form',
        loadChildren: () =>
            import('./page/Pecas/pecas-form/pecas-form.module').then(
                (m) => m.PecasFormPageModule
            ),
    },
    {
        path: 'ciclo-despesca-view',
        loadChildren: () =>
            import(
                './page/ciclo-despesca-view/ciclo-despesca-view.module'
            ).then((m) => m.CicloDespescaViewPageModule),
    },
    {
        path: 'relatorio-folha-recarga',
        loadChildren: () =>
            import(
                './page/relatorios/relatorio-folha-recarga/relatorio-folha-recarga.module'
            ).then((m) => m.RelatorioFolhaRecargaPageModule),
    },
    {
        path: 'oxigenio-view',
        loadChildren: () =>
            import('./page/oxigenio/oxigenio-view/oxigenio-view.module').then(
                (m) => m.OxigenioViewPageModule
            ),
    },
    {
        path: 'oxigenio-form',
        loadChildren: () =>
            import('./page/oxigenio/oxigenio-form/oxigenio-form.module').then(
                (m) => m.OxigenioFormPageModule
            ),
    },
    {
        path: 'troca-de-peca-view',
        loadChildren: () =>
            import(
                './page/troca-peca/troca-de-peca-view/troca-de-peca-view.module'
            ).then((m) => m.TrocaDePecaViewPageModule),
    },
    {
        path: 'troca-peca-info',
        loadChildren: () =>
            import(
                './page/troca-peca/troca-peca-info/troca-peca-info.module'
            ).then((m) => m.TrocaPecaInfoPageModule),
    },
    {
        path: 'suporte-view',
        loadChildren: () =>
            import(
                './page/Suporte-Cadoma/suporte-view/suporte-view.module'
            ).then((m) => m.SuporteViewPageModule),
    },
    {
        path: 'suporte-notificacao',
        loadChildren: () =>
            import(
                './page/Suporte-Cadoma/suporte-notificacao/suporte-notificacao.module'
            ).then((m) => m.SuporteNotificacaoPageModule),
    },
    {
        path: 'pecas-edit-form',
        loadChildren: () =>
            import('./page/Pecas/pecas-edit-form/pecas-edit-form.module').then(
                (m) => m.PecasEditFormPageModule
            ),
    },
    {
        path: 'alimentador-novo',
        loadChildren: () =>
            import(
                './page/alimentador/alimentador-novo/alimentador-novo/alimentador-novo.module'
            ).then((m) => m.AlimentadorNovoPageModule),
    },
    {
        path: 'suporte-cadastro',
        loadChildren: () =>
            import(
                './page/Suporte-Cadoma/suporte-cadastro/suporte-cadastro.module'
            ).then((m) => m.SuporteCadastroPageModule),
    },
  {
    path: 'arracoamento-manual-rapido',
    loadChildren: () => import('./page/arracoamento-manual-rapido/arracoamento-manual-rapido.module').then( m => m.ArracoamentoManualRapidoPageModule)
  },
  {
    path: 'vazao-rapido',
    loadChildren: () => import('./page/vazao-rapido/vazao-rapido.module').then( m => m.VazaoRapidoPageModule)
  },
  {
    path: 'retirada-racao-rapida',
    loadChildren: () => import('./page/retirada-racao-rapida/retirada-racao-rapida.module').then( m => m.RetiradaRacaoRapidaPageModule)
  },
  {
    path: 'lancamento-satisfacao-rapida',
    loadChildren: () => import('./page/lancamento-satisfacao-rapida/lancamento-satisfacao-rapida.module').then( m => m.LancamentoSatisfacaoRapidaPageModule)
  },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
