import { MenssagemService } from './../menssagem/menssagem.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NetworkInterface } from '@ionic-native/network-interface/ngx';
import { NavController } from '@ionic/angular';
import { apiRequest } from 'src/environments/environment';
import { SessionService } from './../session/session.service';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class RedeService {
  private QUANTIDADE_MAX_TENTATIVA = 1;
  // private TIMEOUT: number = 15000;

  public isIntranetCadoma: boolean = false;

  //ionic cordova plugin add cordova-plugin-networkinterface

  constructor(
    public http: HttpClient,
    private networkInterface: NetworkInterface,
    public sessionService: SessionService,
    private navController: NavController,
    private MenssagemService:MenssagemService,
    private storage: Storage
  ) {

  }

  async getInstacia() {
    var instacia: string = await this.storage.get("instanciaUltima");
    return instacia;
  }

  async getBaseUrl() {
    var instacia: string = await this.storage.get("instancia");

    if (this.isIntranetCadoma) {
      return apiRequest.url_rasp + "/api/";
    } else {
      if (instacia == null || instacia.length == 0) {
        //FAM
        // instacia = "5656";
        //Fisher
        // instacia = "5467";
        //instacia = "8080";
        //Localhost
         instacia = "3541";
      }
      return apiRequest.url + ":" + instacia + "/api/";
    }
  }

  verificaConexao(): Promise<string> {

    return new Promise((accept, reject) => {
      this.networkInterface.getWiFiIPAddress()
        .then(address => {
          var string = address.ip;
          console.log("Ip : " + string)
          if (string.includes("10.10.10")) {
          // if (string.includes("192.168.15")) {
            this.isIntranetCadoma = false;
          } else {
            this.isIntranetCadoma = false;
          }

          accept(this.getBaseUrl())

        }).catch(error => {
          // console.error(`Unable to get IP: ${error}`)
          this.isIntranetCadoma = false;
          accept(this.getBaseUrl());
        }
        );
    });
  }

  async get<T>(url: String, tentativa: number = 0): Promise<T> {
    console.log("GET : url = " + url)

    let urlBase = await this.verificaConexao();
    let httpOptions = {}
    if (this.sessionService.session) {
      httpOptions = {
        headers: new HttpHeaders({ 'Authorization': this.sessionService.session.token }),
      }
    }

    try {
      return await this.http.get<T>(urlBase + url, httpOptions).toPromise();
    } catch (error) {

      let renovouToken = await this.verificaErroTokenDesatualizado(error);
      if (renovouToken) {
        return await this.get<T>(url, tentativa);
      } else {
        tentativa++;

        if (tentativa < this.QUANTIDADE_MAX_TENTATIVA) {
          return await this.get<T>(url, tentativa);
        } else {
          throw error;
        }
      }

    }

  }

  async downloadPut(url: string, body: any, fileName: string) {
    let urlBase = await this.verificaConexao();
    let httpOptions = {}
    if (this.sessionService.session) {
      httpOptions = {
        headers: new HttpHeaders({ 'Authorization': this.sessionService.session.token }),
        responseType: 'blob'
      }
    }

    this.http.put(urlBase + url, body, httpOptions)
      .subscribe(blob => {
        const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(blob)
        a.href = objectUrl
        a.download = fileName;
        a.click();
        URL.revokeObjectURL(objectUrl);
      })
  }

  async put<T>(url: String, body: any, tentativa: number = 0): Promise<T> {
    console.log("PUT : url = " + url)

    let urlBase = await this.verificaConexao();

    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': this.sessionService.session.token }),
      // withCredentials:false
    }

    try {
      return await this.http.put<T>(urlBase + url, body, httpOptions).toPromise();
    } catch (error) {

      let renovouToken = await this.verificaErroTokenDesatualizado(error);
      if (renovouToken) {
        return await this.put<T>(url, body, tentativa);
      } else {
        tentativa++;

        if (tentativa < this.QUANTIDADE_MAX_TENTATIVA) {
          return await this.put<T>(url, body, tentativa);
        } else {
          throw error;
        }
      }

    }

  }

  async verificaErroTokenDesatualizado(error) {

    if (error.status == 401) {
      //TODO Renovar a session.
      if (this.sessionService.session) {
        // buscar novo token no servidor/
        await this.renovarToken();
        return true;
      } else {
        this.navController.navigateRoot('/login');
      }
    }

    return false;
  }


  async renovarToken() {
    console.log("renovarToken")

    let user = {
      username: this.sessionService.session.email,
      password: this.sessionService.session.senha
    }
    let urlBase = await this.verificaConexao();


    try {
      let jwt: any = await this.http.post(urlBase + "authenticate", user).toPromise()


      if (jwt) {
        this.sessionService.atualizarToken(jwt.token)
      } else {
        //Usuário mudou de senha, ou foi desativado.
        await this.sessionService.logout();
        throw new TypeError("Usuário ou senha inválidos");
      }

    } catch (error) {
      console.log("Erro do servidor ", error)

      if (error instanceof HttpErrorResponse && error.status == 401) {
        //Usuário mudou de senha, ou foi desativado.
        await this.sessionService.logout();
        throw new TypeError("Usuário ou senha inválidos");
      }

      throw error;
    }

  }

  async cliente() {
    try {
      let urlBase = await this.verificaConexao();
      let aa: any = await this.http.get(urlBase + "cliente").toPromise();
      return aa.nome;
    } catch (erro) {
      console.error("Erro ao buscar cliente ", erro)
    }
    return "ERRO"
  }

  async testarCliente( porta:string) {
    let urlBase = "";
    if (this.isIntranetCadoma) {
      urlBase = apiRequest.url_rasp + "/api/";
    } else {
   
      urlBase = apiRequest.url + ":" + porta + "/api/";
    }

    let aa: any = await this.http.get(urlBase + "cliente").toPromise();
    return aa.nome;
  }

  async login(instancia: string, email: string, password: string) {
    let user = {
      username: email,
      password: password
    }

    this.storage.set("instancia", instancia);
    this.storage.set("instanciaUltima", instancia);
    let urlBase = await this.verificaConexao();
    console.log("urlBase", urlBase)
    let jwt:any;

    try {
      jwt = await this.http.post(urlBase + "authenticate", user).toPromise();

      //Usuário ou senha válido.
      if (jwt) {
        //Depois que logou com sucesso, prepara para salvar a sessão.
        let httpOptions = {
          headers: new HttpHeaders({ 'Authorization': jwt.token }),
          withCredentials: false
        }
        let userSession: any = await this.http.get(urlBase + "session", httpOptions).toPromise();
        await this.sessionService.createSession(email, password, userSession.nome, jwt.token, userSession.baseUrl, userSession.autorizacoes);
      }
    } catch (error) {
      console.error("Erro do servidor ", error)

      if (error instanceof HttpErrorResponse) {
        if (error.status === 0) {
          throw new TypeError("Sem conexão com o servidor ! \n " + urlBase);
        }
        throw new TypeError("Erro desconhecido " + error.statusText);
      }
      
      throw new TypeError("Erro desconhecido " + error);
    }

    if (!jwt) {
      throw new TypeError("Usuário ou senha inválidos");
    }

  }

  async recuperarSenha(username: string) {

    let urlBase = await this.verificaConexao();
    console.log("urlBase", urlBase)

    try {
      await this.http.post(urlBase + "recuperarSenha", username).toPromise();
      // console.log("jwt.token", jwt.token)
    } catch (error) {
      console.log("Erro do servidor ", error)

      if (error instanceof HttpErrorResponse && error.status == 401) {
        throw new TypeError("Usuário ou senha inválidos");
      }
      throw new TypeError("Servidor sem conexão !");
    }

  }

  async salvarSenha(password: String, token:string) {
    let novaSenhaBean = {
      password: password,
      token: token
    }
    console.log(novaSenhaBean)
    let urlBase = await this.verificaConexao();
    console.log("urlBase", urlBase)

    try {
      await this.http.post(urlBase + "salvarSenha", novaSenhaBean).toPromise();
      console.log(novaSenhaBean)
    } catch (error) {
      console.log("Erro do servidor ", error)
      console.log(novaSenhaBean)
      if (error instanceof HttpErrorResponse && error.status == 401) {
        throw new TypeError("Usuário ou senha inválidos");
      }
      throw new TypeError("Servidor sem conexão !");
    }
  }

}
