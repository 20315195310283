import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'instrucaoManutencao'
})
export class InstrucaoManutencaoPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (value == 1) {
      return "Siga o fluxograma de troca de motor e informe abaixo o identificador do novo motor:"
    } else if (value == 2) {
      return "Siga o fluxograma de troca de bateria e informe abaixo o identificador da nova bateria:"
    } else if (value == 3) {
      return "Siga o fluxograma de troca de placa eletrônica e informe abaixo o identificador da nova placa:"
    } else if (value == 4) {
      return "Selecione a nova caixa inserida abaixo:"
    } else if (value == 5) {
      return "Siga o fluxograma de troca de rosca e informe abaixo o identificador da nova rosca:"
    } else if (value == 6) {
      return "Siga o fluxograma de alimentador entupido"
    } else if (value == 7) {
      return "Siga o fluxograma de motor travado"
    } else if (value == 8) {
      return "Altere o identificador das peça trocadas abaixo:"
    } else if (value == 9) {
      return "Siga o fluxograma de alimentador desconectados na água"
    } 
    return "";
  }

}
