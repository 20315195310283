import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'codigoPecaZeroSemPeca',
})
export class CodigoPecaZeroSemPecaPipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        if (value === 0) {
            return 'Sem peça';
        } else return value;
    }
}
