export class Session {
    email: string; // HH:mm
    nome: string;
    token: string; // Minutos  hibernacao
    baseUrl:string;
    senha:string;
    ultimaDataNotificacao: Date;
    dataAtualizacaoSessao: Date;
    autorizacoes: string[];
}
