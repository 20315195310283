export enum PecaTipo {

    BATERIA = 0,
    MOTOR = 1,
    ANTENA = 2,
    ROSCA = 3,
    PCB = 4,
    PAINELSOLAR = 5,
    RESERVATORIO = 6,
    SENSORTEMPERATURA = 7,
    CAIXA = 8
}