import { NgxMatTimepickerModule, NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule, MatInputModule, MatDatepickerModule, MatDialogModule } from '@angular/material';
import { IonicModule } from '@ionic/angular';
import { PipeModule } from 'src/app/pipe/pipe/pipe.module';
import { NgxMatDateFnsDateModule } from '../datefns-date-adapter/datefns-date-adapter.module';
import { CadAlertComponent } from './cad-alert.component';

@NgModule({  
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PipeModule,
    ReactiveFormsModule,    
    MatDialogModule,
  ],
  declarations: [CadAlertComponent],
  exports: [
    CadAlertComponent
  ]
})
export class CadAlertModule {}
