import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AlertController } from '@ionic/angular';
import { CicloVazaoRapidaBean } from 'src/app/model/ciclo-vazao-rapida-bean';

@Component({
    selector: 'app-confirma-informacoes-para-envio-modal',
    templateUrl: './confirma-informacoes-para-envio-modal.component.html',
    styleUrls: ['./confirma-informacoes-para-envio-modal.component.scss'],
})
export class ConfirmaInformacoesParaEnvioModalComponent implements OnInit {
    public dados;
    constructor(
        public alertController: AlertController,
        public datepipe: DatePipe,
        private dialogRef: MatDialogRef<ConfirmaInformacoesParaEnvioModalComponent>,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        this.dados = data;
        console.log(data.tituloColunas);
        console.log(data.textoTitulo);
        console.log(data.dados);
        console.log(data.textoCancelar);
        console.log(data.textoSalvar);
    }

    ngOnInit() {}
    cancelar() {
        this.dialogRef.close();
    }

    enviarDados() {
        this.dialogRef.close({ save: true });
    }

    async salvar() {
        this.enviarDados();
    }
}
