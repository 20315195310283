import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';
import { TipoPermissao } from 'src/app/enum/tipo-permissao.enum';
import { MenuNotificacaoBean } from './../../model/menu-notificacao-bean';
import { Session } from './../../model/session/session';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  
  private currentUser: BehaviorSubject<any> = new BehaviorSubject(null);
  constructor(private storage: Storage
    , public http: HttpClient
    , private navController: NavController) {

  }

  public session:Session;
  public isTecnicoCadoma:boolean;
  public isFinaceiro:boolean;
  public cliente:any;

  async createSession(email: string, senha: string, nome:string, token: string, baseUrl: string, autorizacoes: string[]) {
    let s:Session = new Session();
    s.email = email;
    s.token = token;
    s.senha = senha;
    s.nome = nome;
    s.baseUrl = baseUrl;
    s.autorizacoes = autorizacoes;
    s.ultimaDataNotificacao = new Date();
    s.dataAtualizacaoSessao = new Date();

    // set a key/value
    await this.storage.set('s', s);

    this.session = s;
    this.currentUser.next(s);
  }

  async atualizarToken(token: string) {
    this.session.token = token;

    this.currentUser.next(this.session);
   await this.storage.set('s', this.session);
  }
  
  async initSession() {
    this.session = await this.storage.get('s');
    this.isTecnicoCadoma = await this.verificarPermissao(TipoPermissao.TECNICOCADOMA);
  }

  async initCliente(cliente) {
    this.cliente = cliente
  }

  async verificarPermissao(permissao: string):Promise<boolean> {
    this.session = await this.storage.get('s');
    let autorizacoes = this.session ? this.session.autorizacoes : null;   
    return autorizacoes ? !!autorizacoes.find(element => element === permissao.toUpperCase()) : false;
  }

  async verificarPermissoes(permissoes: string[]):Promise<boolean> {
    let retorno = false;

    for (let index = 0; index < permissoes.length; index++) {
      if(await this.verificarPermissao(permissoes[index])){
        retorno = true;
      }
    }
    return retorno;
  }

  async logout() {
    await this.storage.remove('s');
    await this.storage.remove("instancia");
    this.session = null;
    this.currentUser.next(false);
    this.navController.navigateRoot('/login');
  }

  async salvarMenuNotificacaoBean(bean: MenuNotificacaoBean) {
    // set a key/value
    await this.storage.set('n', bean);
  }

  async getMenuNotificacaoBean() {
    // set a key/value
    return await this.storage.get('n');
  }

  getUser() {
    return this.currentUser.asObservable();
  }
}
