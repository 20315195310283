import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'double'
})
export class DoublePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {

    if (value) {
      try {
        return Number.isInteger(value) ? value.toFixed(2).replaceAll('.',',') : parseFloat((value).toFixed(2)).toLocaleString('de-DE');
      } catch (error) {
        // console.error("DoublePipe : " + value)
        return '';
      }
      
    } else if (value === 0) {
      return "0,00";
    } else {
      return "";
    }
  }

}
