import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { SessionService } from '../service/session/session.service';
 
@Directive({
  selector: '[appHasPermission]'
})
export class HasPermissionDirective {
 
  @Input('appHasPermission') permissions: string[];
 
  constructor(private authService: SessionService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef) { }
 
  ngOnInit() {
    this.authService.getUser().subscribe(async _ => {
      if (await this.authService.verificarPermissoes(this.permissions)) {
        this.viewContainer.clear();
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    });
  }
}