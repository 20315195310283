import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-aviso-modal',
    templateUrl: './aviso-modal.component.html',
    styleUrls: ['./aviso-modal.component.scss'],
})
export class AvisoModalComponent implements OnInit {
    constructor(
        private dialogRef: MatDialogRef<AvisoModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
        //data.textoLegenda
        //data.textoSair
    }

    cancelar() {
        this.dialogRef.close(false);
    }

    confirmar() {
        this.dialogRef.close(true);
    }
}
