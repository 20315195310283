import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-cad-alert',
  templateUrl: './cad-alert.component.html',
  styleUrls: ['./cad-alert.component.scss'],
})
export class CadAlertComponent implements OnInit {
  header: string;
  message: string;
  textAccept: string;
  textCancel: string;

  constructor(private dialogRef: MatDialogRef<CadAlertComponent>, @Inject(MAT_DIALOG_DATA) data) { 
    this.header = data.header;
    this.message = data.message;
    this.textAccept = data.textAccept;
    this.textCancel = data.textCancel;
  }
  
  ngOnInit() {}

  actionOk(){
    this.dialogRef.close({"accepted": true})
  }
}
