import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dataNotificacao',
})
export class DataNotificacao implements PipeTransform {
    transform(value: any): string {
        if (value) {
            // Converte a string da data para um objeto Date
            const date = new Date(value);

            // Obtém as partes da data (hora, minuto, dia e mês)
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');

            // Formata a data como "hh:mm dd/mm"
            return `${hours}:${minutes} ${day}/${month}`;
        }

        return '';
    }
}
