import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'datas',
})
export class DatasPipe implements PipeTransform {

  constructor(public datepipe: DatePipe
    ) {}

  transform(value: any, ...args: any[]): any {
      const format = args.length > 0 ? args[0] : 'dd/MM/yyyy HH:mm:ss';
      let latest_date = this.datepipe.transform(value, format);
      return latest_date
  }

}
