import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'quilogramas',
})
export class QuilogramasPipe implements PipeTransform {
    transform(value: number): any {
        if (value) {
            try {
                const formattedValue = (
                    Math.round(value) / 1000
                ).toLocaleString('pt-BR', { maximumFractionDigits: 2 });
                return formattedValue;
            } catch (error) {
                return '0';
            }
        } else {
            return '0';
        }
    }
}
