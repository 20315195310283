import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reservatorioLitroKilo'
})
export class ReservatorioLitroKiloPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (value === 150) {
      return "150L (75Kg)"
    } else if (value === 300) {
      return "300L (125Kg)"
    } else if (value === 800) {
      return "800L (375Kg)"
    } else if (value === 2400) {
      return "2400L (1200Kg)"
    }
  }

}
