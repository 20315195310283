import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({
    providedIn: 'root',
})
export class InfoTransferComponentsService {
    private lancamentosPendentesArray: boolean[] = new Array(9); //Descrição: mortalidade(0), desligamentos(1), taxas extras(2)...

    constructor(private storage: Storage) {
        this.inicializarLancamentosPendentesArray();

        setInterval(() => {
            this.salvaBackup();
        }, 1000);
    }

    private async inicializarLancamentosPendentesArray() {
        const listLancamentos = await this.storage.get(
            'lancamentosPendentesArray'
        );
        this.lancamentosPendentesArray =
            listLancamentos || new Array(9).fill(false);
    }

    private salvaBackup() {
        this.storage.set(
            'lancamentosPendentesArray',
            this.lancamentosPendentesArray
        );
    }

    //Conta quantos lançamentos estão pendentes para ser exibido
    public getLancamentosPendentes(): number {
        let contador: number = 0;

        this.lancamentosPendentesArray.forEach((item) => {
            if (item) contador++;
        });

        return contador;
    }

    /**
     * Seta o array de lancamentos pendentes.
     *
     * Recebe um boolean e um index correspondente a tela de lançamento , onde mortalidade(0), desligamentos(1), taxas extras(2)...
     *
     * @param {boolean} data - O valor a ser setado no array de boolean
     * @param {number} index - O index do array.
     * @returns {void}
     * **/
    public setArrayLancamentosPendentes(data: boolean, index: number) {
        this.lancamentosPendentesArray[index] = data;
    }

    public saveLancamentosPendentesArray(): void {
        localStorage.set(
            'lancamentosPendentesArray',
            this.lancamentosPendentesArray
        );
    }
}
