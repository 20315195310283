import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'gramas',
})
export class GramasPipe implements PipeTransform {
    transform(value) {
        value = parseFloat(value); //!Necessário por conta da máscara que deve ter o tipo do input como text
        if (value || value === 0) {
            if (value >= 1000000 || value <= -1000000) {
                return (
                    parseFloat((value / 1000000).toFixed(2)).toLocaleString(
                        'de-DE'
                    ) + ' t'
                );
            } else if (value >= 1000 || value <= -1000) {
                return (
                    parseFloat((value / 1000).toFixed(2)).toLocaleString(
                        'de-DE'
                    ) + ' kg'
                );
            } else {
                return (
                    parseFloat(value.toFixed(2)).toLocaleString('de-DE') + ' g'
                );
            }
        } else {
            return '';
        }
    }
}
