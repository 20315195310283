import { PecaTipo } from 'src/app/model/peca-tipo.enum';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tipoPeca'
})
export class TipoPecaPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {

    if (value === "BATERIA" || value === PecaTipo.BATERIA) {
      return "Bateria"
    } else if (value === "MOTOR" || value === PecaTipo.MOTOR) {
      return "Motor"
    } else if (value === "ANTENA" || value === PecaTipo.ANTENA) {
      return "Antena"
    } else if (value === "ROSCA" || value === PecaTipo.ROSCA) {
      return "Rosca"
    } else if (value === "PCB" || value === PecaTipo.PCB) {
      return "PCB"
    } else if (value === "PAINEL_SOLAR" || value === PecaTipo.PAINELSOLAR) {
      return "Painel solar"
    } else if (value === "RESERVATORIO" || value === PecaTipo.RESERVATORIO) {
      return "Reservatório"
    } else if (value === "SENSORTEMPERATURA" || value === PecaTipo.SENSORTEMPERATURA) {
      return "Sensor de temperatura"
    } else if (value === "CAIXA" || value === PecaTipo.CAIXA) {
      return "Caixa"
    }
  }

}
