import { PecaTipo } from 'src/app/model/peca-tipo.enum';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tipoPecaParaTipoManutencao'
})
export class TipoPecaParaTipoManutencaoPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (value == 'MOTOR') {
      return 1
    } else if (value == 'BATERIA') {
      return 2
    } else if (value == 'PCB') {
      return 3
    } else if (value == 'ROSCA') {
      return 5
    }
    return 10;
  }

}
